.sidebar-container {
  background: #f7f8fa;
}
.search-field input {
  border-radius: 8px;
}

.sidebar-item svg {
  color: unset;
}
